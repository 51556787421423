import ym from 'react-yandex-metrika';

const yaEvent = event => {
  ym('reachGoal', event);
};

export {
  yaEvent
};

function getClientId () {
  if (typeof document === 'undefined') {
    return null;
  }

  const match = document.cookie.match('(?:^|;)\\s*_ym_uid=([^;]*)');
  return match ? decodeURIComponent(match[1]) : null;
}

export const metrikaClientId = getClientId();
