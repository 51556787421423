import { useState, useEffect } from 'react';

export function useOnScreen (ref, rootMargin = '0px') {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.unobserve(ref.current);
    };
  }, [ref, rootMargin]);

  return isIntersecting;
}

export function useLinkToApp () {
  const [linkToApp, setLinkToApp] = useState('');
  const [linkToRegistration, setLinkToRegistration] = useState('');

  useEffect(() => {
    const { hostname, protocol, search } = window.location;

    let baseUrl;

    if (['sberpodbor.ru','sberpodbor.wtf'].includes(hostname)) {
      baseUrl = `${protocol}//app.${hostname}`;
    } else if (hostname === 'localhost') {
      baseUrl = 'http://localhost:3000';
    } else {
      const splittedHost = hostname.split('.');
      const branchName = splittedHost.slice(0, 1);
      const domain = splittedHost.slice(1).join('.');
       baseUrl = `${protocol}//${branchName}.app.${domain}`;
    }

    setLinkToApp(baseUrl);
    setLinkToRegistration(`${linkToApp}/sign-up/company${search}`);
  });

  return {
    linkToApp,
    linkToRegistration
  };
}
export function useLinkToRabotaRu () {
  const RABOTA_RU_HOSTS_ENUM = {
    DEV: 'dione.rabota.space',
    STAGE: 'rabota.wtf',
    PROD: 'rabota.ru'
  };
  const [linkToRabotaRu, setLinkToRabotaRu] = useState('');
  const [hostNameRabotaRu, setHostNameRabotaRu] = useState('');
  useEffect(() => {
    const { hostname } = window.location;

    let baseUrl;

    if (hostname==='sberpodbor.ru') {
      setHostNameRabotaRu(RABOTA_RU_HOSTS_ENUM.PROD);
      baseUrl = `https://${RABOTA_RU_HOSTS_ENUM.PROD}`;
    } else if (hostname === 'sberpodbor.wtf'){
      setHostNameRabotaRu(RABOTA_RU_HOSTS_ENUM.STAGE);
      baseUrl =  `https://${RABOTA_RU_HOSTS_ENUM.STAGE}`;
    } else {
      setHostNameRabotaRu(RABOTA_RU_HOSTS_ENUM.DEV);
      baseUrl = `https://${RABOTA_RU_HOSTS_ENUM.DEV}`;
    }

    setLinkToRabotaRu(baseUrl);
  });

  return {
    linkToRabotaRu,
    hostNameRabotaRu
  };
}

export function usePhoneField () {
  const normalizePhone = (value, previousValue) => {
    if (!value?.length) return value;

    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength === 1) return '+' + currentValue;
      if (cvLength <= 4) return `+${currentValue.slice(0, 1)} (${currentValue.slice(1)}`;
      if (cvLength <= 7) return `+${currentValue.slice(0, 1)} (${currentValue.slice(1, 4)}) ${currentValue.slice(4)}`;
      if (cvLength <= 9) return `+${currentValue.slice(0, 1)} (${currentValue.slice(1, 4)}) ${currentValue.slice(4, 7)} ${currentValue.slice(7)}`;
      return `+${currentValue.slice(0, 1)} (${currentValue.slice(1, 4)}) ${currentValue.slice(4, 7)} ${currentValue.slice(7, 9)} ${currentValue.slice(9, 11)}`;
    }
  };

  return {
    normalizePhone
  };
}
