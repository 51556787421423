import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import * as s from './button.module.scss';

const Button = ({
  className,
  black,
  outline,
  small,
  medium,
  large,
  round,
  link,
  text,
  href,
  type,
  noTitle,
  newWindow,
  onClick,
  children,
  solid,
  white,
  block,
  disabled
}) => {
  const classes = cn(className, {
    [s.button]: true,
    [s.buttonDefault]: !black && !link,
    [s.black]: black,
    [s.link]: link,
    [s.outline]: outline,
    [s.small]: small,
    [s.medium]: medium,
    [s.large]: large,
    [s.round]: round,
    [s.solid]: solid,
    [s.white]: white,
    [s.block]: block
  });

  const Tag = href ? 'a' : 'button';

  const props = {};

  if (Tag === 'a') {
    props.href = href;
    props.rel = 'noopener noreferrer';
    props.target = newWindow ? '_blank' : '_self';
    props.title = !noTitle ? text : '';
  } else {
    props.type = type;
    props.disabled = disabled;
  }

  return (
    <Tag
      onClick={onClick}
      className={classes}
      {...props}
    >
      { children || text }
    </Tag>
  );
};

Button.propTypes = {
  black: PropTypes.bool,
  link: PropTypes.bool,
  className: PropTypes.string,
  href: PropTypes.string,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  white: PropTypes.bool,
  block: PropTypes.bool,
  linkBlue: PropTypes.bool,
  round: PropTypes.bool,
  outline: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  type: 'button',
  round: true
};

export default Button;
