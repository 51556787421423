import instance from '../instance';
import { isProduction, isStaging } from '../../../helper/system';
import { metrikaClientId } from '../../../helper/metrika';

export function dispatchEvent (payload) {
  const params = {
    ...payload
  };

  if (!params.payload) {
    params.payload = {};
  }

  params.payload = {
    ...params.payload,
    clientDatetime: +new Date()
  };

  if (!(isProduction && !isStaging)) {
    params.payload.isTest = true;
  }

  params.payload.additionally = {
    ...params.payload.additionally,
    ym_client_id: metrikaClientId
  };

  return instance.post('/public/metric/event/dispatch', params);
}
