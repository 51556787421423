/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function Seo({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description,
            image,
            siteUrl
          }
        }
      }
    `
  );

  const metaTitle = title || site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;

  const metaProperties = [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, shrink-to-fit=yes, viewport-fit=cover'
    },
    {
      name: 'description',
      content: metaDescription
    },
    {
      property: 'og:title',
      content: 'СберПодбор — бесплатная CRM-система для рекрутеров'
    },
    {
      property: 'og:description',
      content: 'Ведите базу кандидатов, работайте со всеми площадками из одного окна, отслеживайте аналитику. Подробнее на sberpodbor.ru'
    },
    {
      property: 'og:type',
      content: 'website'
    },
    {
      property: 'vk:image',
      content: `${site.siteMetadata.siteUrl}${site.siteMetadata.image}`
    },
    {
      property: 'twitter:image',
      content: `${site.siteMetadata.siteUrl}${site.siteMetadata.image}`
    },
    {
      property: 'og:image',
      content: `${site.siteMetadata.siteUrl}${site.siteMetadata.image}`
    },
    {
      property: 'og:image:type',
      content: 'image/png'
    },
    {
      property: 'og:image:width',
      content: '1200'
    },
    {
      property: 'og:image:height',
      content: '630'
    },
    {
      name: 'yandex-verification',
      content: '6d3503a78fa0c873'
    },
    {
      name: 'google-site-verification',
      content: 'z91zAIAPdGNTFkPO_jczIRyGuoq6qR5wNn8LuaGdNNE'
    },
  ];

  metaProperties.forEach(item => {
    const [name] = Object.entries(item);
    const [key, value] = name;

    const isIncludes = meta.find(item => {
      return item[key] === value;
    });

    if (!isIncludes) {
      meta.push(item);
    }
  });

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={metaTitle}
      meta={meta}
    >
    </Helmet>
  );
}

Seo.defaultProps = {
  lang: 'ru',
  meta: [],
  description: ''
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
};

export default Seo;
